import { deleteToken } from "../Api/deleteToken"
import { getToken } from "../Api/getToken"
import { postJsonToken } from "../Api/postJsonToken"
import { putToken } from "../Api/putToken"

// Admin ekleme
export const addAdminManager = (form) => {
    let data = {
        'password': form.password,
        'name': form.name,
        'surname': form.surname,
        'email': form.email,
        'phoneNo': `+49${form.phoneNo}`,
        'role': form.role
    }
    return postJsonToken(`/Admin/Register`, data)
}

// Adminleri listeleme
export const getAdmins = (page, search = '', status = -1, limit) => {
    return getToken(`/Admin?page=${page}&limit=${limit}${search.length > 0 ? `&searchKey=${search}` : ''}`)
}

// Admin güncelleme
export const editAdminManager = (val, form) => {
    let data = {
        'password': form.password,
        'name': form.name,
        'surname': form.surname,
        'email': form.email,
        'phoneNo': `+49${form.phoneNo}`,
        'role': form.role,
        'isActive': form.isActive
    }
    return putToken(`/Admin/${val}`, data)
}

// Admin silme
export const deleteAdminManager = (val) => {
    return deleteToken(`/Admin/${val}`)
}

// Admin detay getirme
export const infoAdminManager = (val) => {
    return getToken(`/Admin/${val}`)
}

// Kişiye admin atama
export const assignmentAdminManager = (val, id, taxYear) => {
    let data = {
        'adminId': val,
        "taxYear": taxYear
    }
    return putToken(`/Assignment/${id}`, data)
}

// Admin filtreleme
export const filterAdminManager = (id) => {
    return getToken(id == null ? '/Users' : `/Users?assignId=${id.id}`)
}

export const documentNote = (userId, taxYear, directoryType) => {
    return getToken(`/DocumentNote?userId=${userId}&taxYear=${taxYear}&directoryType=${directoryType}`)
}