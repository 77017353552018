<template>
  <div>
    <TrashUser></TrashUser>
  </div>
</template>

<script>
import TrashUser from "@/components/admin/trashUser";
export default {
  components: { TrashUser },
};
</script>

<style></style>
